import React from 'react';
import { graphql, Link } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import LazyLoad from 'react-lazyload';
import Textbox from '../components/text/Textbox';

const Event = styled.div`
  margin-bottom: 144px;
  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;

const EventTitle = styled.div`
  color: #000;
  padding: 30px 0 0 0;
  & h2 {
    @media (max-width: 767px) {
      font-size: 27px;
    }
    @media (max-width: 420px) {
      font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
    }
  }
`;

const PublicationTitle = styled.h1`
  font-size: 28px;
  text-align: center;
  margin-top: 75px;
  margin-bottom: 144px;

  @media (max-width: 767px) {
    font-size: 27px;
  }

  @media (max-width: 420px) {
    margin-top: 0;
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  padding-top: 66.3%;
  position: relative;
  overflow: hidden;
`;

const ImagePlaceholder = styled.div`
  width: 100%;
  padding-top: 66.3%;
  position: relative;
  overflow: hidden;
  @media (max-width: 767px) {
    display: none;
  }
`;

const LabelContainer = styled.span`
  color: red;
  text-transform: uppercase;
`;

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
`;

const LandscapeImageContainer = styled.div`
  width: 100%;
  padding-top: 66.3%;
  position: relative;
  overflow: hidden;
`;

const LandscapeStyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const ReadMoreLink = styled(Link)`
  text-decoration: none;
  color: red;
  &:visited {
    color: red;
  }
`;

const EventLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
    * {
      color: inherit;
    }
    span,
    a {
      color: red;
    }
  }
`;

const Projects = ({ data }) => {
  const content = data.prismicCurrent.data.posts
    .filter(
      (content) =>
        content.post.document &&
        content.post.document.data.display_in_current === 'True'
    )
    .map((content, index) => {
      if (content.post.document) {
        const { featured } = content.post.document.data;
        let dimensions = content.post.document.data.image.dimensions;
        let imageWidth = dimensions ? dimensions.width : null;
        let imageHeight = dimensions ? dimensions.height : null;

        const colSize = featured ? 12 : 6;

        const link =
          content.post.document.data.link && content.post.document.data.link.uid
            ? content.post.document.data.link.type === 'news' ||
              content.post.document.data.link.type === 'event'
              ? '/archive/' + content.post.document.data.link.uid
              : content.post.document.data.link.type +
                content.post.document.data.link.uid
            : content.post.document.uid != null
              ? '/current/' + content.post.document.uid
              : null;

        const isLandscape = imageWidth > imageHeight;

        return (
          <Col col={12} sm={colSize} key={index}>
            <LazyLoad height={400}>
              {link ? (
                <EventLink to={link}>
                  <Event>
                    <div>
                      {content.post.document.data.image.url ? (
                        isLandscape ? (
                          <LandscapeImageContainer>
                            <LandscapeStyledImage
                              src={content.post.document.data.image.url}
                              loading={index <= 2 ? 'auto' : 'lazy'}
                              alt={content.post.document.data.image.alt}
                            />
                          </LandscapeImageContainer>
                        ) : (
                          <ImageContainer>
                            <StyledImage
                              src={content.post.document.data.image.url}
                              loading={index <= 2 ? 'auto' : 'lazy'}
                              alt={content.post.document.data.image.alt}
                            />
                          </ImageContainer>
                        )
                      ) : (
                        <ImagePlaceholder></ImagePlaceholder>
                      )}

                      <EventTitle>
                        <h2>
                          {content.post.document.data.label ? (
                            <LabelContainer>
                              {`${content.post.document.data.label} `}
                            </LabelContainer>
                          ) : null}
                          {content.post.document.data.title.text}
                        </h2>
                        {content.post.document.data.lead_in && (
                          <Textbox
                            margin={`20px 0px`}
                            mobileMargin={`26px auto 20px auto`}
                            textAlign={`left`}
                            text={content.post.document.data.lead_in.html}
                          />
                        )}
                        <ReadMoreLink to={link}>Read more</ReadMoreLink>
                      </EventTitle>
                    </div>
                  </Event>
                </EventLink>
              ) : (
                <Event>
                  <div>
                    {content.post.document.data.image.url ? (
                      isLandscape ? (
                        <LandscapeImageContainer>
                          <LandscapeStyledImage
                            src={content.post.document.data.image.url}
                            loading={index <= 2 ? 'auto' : 'lazy'}
                            alt={content.post.document.data.image.alt}
                          />
                        </LandscapeImageContainer>
                      ) : (
                        <ImageContainer>
                          <StyledImage
                            src={content.post.document.data.image.url}
                            loading={index <= 2 ? 'auto' : 'lazy'}
                            alt={content.post.document.data.image.alt}
                          />
                        </ImageContainer>
                      )
                    ) : (
                      <ImagePlaceholder></ImagePlaceholder>
                    )}

                    <EventTitle>
                      <h2>
                        {content.post.document.data.label ? (
                          <LabelContainer>
                            {`${content.post.document.data.label} `}
                          </LabelContainer>
                        ) : null}
                        {content.post.document.data.title.text}
                      </h2>
                      {content.post.document.data.lead_in && (
                        <Textbox
                          margin={`20px 0px`}
                          mobileMargin={`26px auto 20px auto`}
                          textAlign={`left`}
                          text={content.post.document.data.lead_in.html}
                        />
                      )}
                    </EventTitle>
                  </div>
                </Event>
              )}
            </LazyLoad>
          </Col>
        );
      }
      return null;
    });

  return (
    <>
      <Helmet title={`Current - Askeaton Contemporary Arts`} />
      <Col col={12} md={10}>
        <PublicationTitle>{'Current'}</PublicationTitle>

        <Row>{content}</Row>
      </Col>
    </>
  );
};

export default Projects;

export const query = graphql`
  {
    prismicCurrent {
      data {
        posts {
          post {
            document {
              ... on PrismicEvent {
                id
                uid
                data {
                  image {
                    url
                    alt
                    dimensions {
                      width
                      height
                    }
                  }
                  title {
                    text
                  }
                  display_in_current
                  featured
                }
                type
              }
              ... on PrismicNews {
                id
                uid
                data {
                  title {
                    text
                  }
                  label
                  text {
                    html
                  }
                  lead_in {
                    html
                  }
                  image {
                    url
                    alt
                    dimensions {
                      width
                      height
                    }
                  }
                  link {
                    uid
                    type
                  }
                  date
                  location
                  display_in_current
                  featured
                }
                type
              }
            }
          }
        }
      }
    }
  }
`;
