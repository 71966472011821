import React from 'react';
import styled from 'styled-components';

const TextContainer = styled.div`
  max-width: ${props => (props.maxWidth ? props.maxWidth : '655px')};

  margin: ${props => props.margin || '0 auto 60px auto'};

  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};

  & p:first-of-type {
    margin-top: ${props =>
      props.paragraphTopMargin ? props.paragraphTopMargin : '1em'};
  }

  @media (max-width: 767px) {
    margin: ${props => props.mobileMargin || '40px auto'};
  }

  & a {
    text-decoration: none;
    color: red;
  }

  & h2 {
    @media (max-width: 767px) {
      font-size: 27px;
    }

    @media (max-width: 420px) {
      font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
    }
  }
`;

const Textbox = ({
  textAlign,
  text,
  margin,
  mobileMargin,
  maxWidth,
  paragraphTopMargin,
}) => (
  <TextContainer
    maxWidth={maxWidth}
    textAlign={textAlign}
    margin={margin}
    mobileMargin={mobileMargin}
    paragraphTopMargin={paragraphTopMargin}
    dangerouslySetInnerHTML={{
      __html: text,
    }}
  />
);

export default Textbox;
